import React, { useEffect, useRef } from "react";
import ProjectCard from "./ProjectCard";
import "../../../styles/components/AllProjects/Projects.css";

const Projects = () => {
  const projects = [
    {
      title: "AngelesBurguer",
      image: "angelesburguer.png",
      technologies: [
        "React",
        "Node.js",
        "Firebase",
      ] /* MAXIMO 4 PARA QUE SE VEA BIEN*/,
      description: {
        en: "AngelesBurguer is a mobile application designed for a restaurant that facilitates order management. Users can view products, manage their cart, and place orders using various payment methods. The app includes user authentication and personal data management, providing a smooth experience for both customers and staff. Orders are stored in Firebase, with details such as total cost, payment method, and customer information securely stored. Additionally, it features an admin mode, which allows managing all data from within the application itself.",
        esp: "AngelesBurguer es una aplicación móvil diseñada para un restaurante que facilita la gestión de pedidos. Los usuarios pueden ver los productos, gestionar su carrito y realizar pedidos utilizando varios métodos de pago. La app incluye autenticación de usuarios y gestión de datos personales, brindando una experiencia fluida tanto para clientes como para el personal. Los pedidos se almacenan en Firebase, con detalles como el costo total, método de pago e información del cliente de manera segura. Además, cuenta con un modo administrador, que permite gestionar todos los datos desde la propia aplicación." /* 750 CARACTERES MAS O MENOS PARA QUE SE VEA BIEN.*/,
      },
      link: "https://play.google.com/store/apps/details?id=com.appAngelesBurguer&hl=es",
      projectImages: ["AB1.PNG", "AB2.PNG", "AB3.PNG", "AB4.PNG", "AB5.PNG", "AB6.PNG", "AB7.PNG", "AB8.PNG", "AB9.PNG", "AB10.PNG", "AB11.PNG", "AB12.PNG", "AB13.PNG", "AB14.PNG"],
    },
    // ... tus proyectos ...
  ];

  const sectionRefs = useRef([]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    // Capture the current refs
    const currentRefs = sectionRefs.current.filter(Boolean);

    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      // Use the captured refs in the cleanup function
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <section className="projects-container">
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div
            className="project-section"
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <ProjectCard
              title={project.title}
              description={project.description}
              technologies={project.technologies}
              image={project.image}
              link={project.link}
              projectImages={project.projectImages}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
