import React, { useEffect, useRef } from "react";
import "../../styles/components/About.css";
import {
  FaUser,
  FaBriefcase,
  FaCode,
  FaRocket,
  FaFutbol,
} from "react-icons/fa";
import { useLanguage } from "../../hooks/LanguageContext";

const translations = {
  es: {
    knowMe: "Conóceme",
    knowMeContent: (
      <>
        Soy <span style={{ color: "#61dafb" }}>Miguel Olteanu</span>, una
        persona cuyo objetivo es mejorar y aprender todos los días, tanto en el
        ámbito profesional como personal.
        <br />
        <br />
        Me considero una{" "}
        <span style={{ color: "#61dafb" }}>persona dedicada </span> a sus
        objetivos y <span style={{ color: "#61dafb" }}>perseverante</span> hasta
        alcanzarlos. Esta determinación me ha llevado a enfrentar desafíos con
        confianza y a no rendirme ante las adversidades, ya sea en proyectos
        personales o en mi desarrollo profesional.
        <br />
        <br />
        Mi enfoque es siempre mantener una{" "}
        <span style={{ color: "#61dafb" }}>actitud positiva</span> y valorar
        cada experiencia que la vida me ofrece.
      </>
    ),
    professionalPath: "Trayectoria Profesional",
    professionalPathContent: (
      <>
        Actualmente, estoy en búsqueda de{" "}
        <span style={{ color: "#61dafb" }}>prácticas profesionales</span> para
        dar mis primeros pasos en el ámbito laboral y aplicar mis conocimientos
        en proyectos reales.
        <br />
        <br />
        Durante mis estudios, he trabajado en{" "}
        <span style={{ color: "#61dafb" }}>proyectos</span> que han fortalecido
        mis habilidades en desarrollo web y móvil, así como en la gestión de
        bases de datos, fundamentos teóricos y la creación de interfaces
        optimizadas.
      </>
    ),
    technicalSkills: "Habilidades Técnicas",
    technicalSkillsContent: (
      <>
        A lo largo de mi experiencia, tanto en la universidad como por mi
        cuenta, he adquirido habilidades en tecnologías como{" "}
        <span style={{ color: "#61dafb" }}>JavaScript</span>,{" "}
        <span style={{ color: "#61dafb" }}>Java</span>,{" "}
        <span style={{ color: "#61dafb" }}>React</span>,{" "}
        <span style={{ color: "#61dafb" }}>Docker</span>,{" "}
        <span style={{ color: "#61dafb" }}>Git</span>,{" "}
        <span style={{ color: "#61dafb" }}>SQL</span> y{" "}
        <span style={{ color: "#61dafb" }}>Node.js</span>.
        <br />
        <br />
        Además, tengo experiencia en bases de datos, como{" "}
        <span style={{ color: "#61dafb" }}>Firebase</span>, y en optimizar la{" "}
        <span style={{ color: "#61dafb" }}>UX/UI</span>, lo que me permite
        desarrollar proyectos completos, desde el diseño hasta su
        implementación.
      </>
    ),
    hobbies: "Hobbies e Intereses",
    hobbiesContent: (
      <>
        Además de mi carrera como ingeniero de software, el{" "}
        <span style={{ color: "#61dafb" }}>deporte</span> es fundamental en mi
        vida.
        <br />
        <br />
        Soy un gran aficionado al{" "}
        <span style={{ color: "#61dafb" }}>fútbol</span>, un deporte que
        disfruto tanto como espectador como jugador desde que tengo uso de
        razón. También me gusta realizar{" "}
        <span style={{ color: "#61dafb" }}>ejercicio</span> diariamente, lo cual
        no solo me ayuda a mantener una vida saludable, sino que también mejora
        notablemente mi estado de ánimo. Además, me encanta ver{" "}
        <span style={{ color: "#61dafb" }}>series</span> y pasar tiempo jugando
        y paseando con mis <span style={{ color: "#61dafb" }}>perros</span>.
        <br />
        <br />
        Estas actividades son esenciales para mí, ya que me ayudan a desconectar
        y aportan equilibrio a mi vida.
      </>
    ),
    nextSteps: "Próximos pasos",
    nextStepsContent: (
      <>
        Mi enfoque a futuro es continuar desarrollando mis habilidades y
        especializarme en desarrollo web y móvil, utilizando principalmente{" "}
        <span style={{ color: "#61dafb" }}>React</span>, debido a su popularidad
        y eficiencia.
        <br />
        <br />
        Actualmente, estoy trabajando en el{" "}
        <span style={{ color: "#61dafb" }}>desarrollo de varios proyectos</span>
        . Estos proyectos, además de crear algo relevante, me permitirán
        aumentar mis habilidades y experiencia.
        <br />
        <br />
        Mi objetivo principal es mantener un aprendizaje continuo y una mejora
        constante, tanto a nivel personal como profesional.
      </>
    ),
    viewCV: "Ver CV",
  },

  en: {
    knowMe: "Get to Know Me",
    knowMeContent: (
      <>
        I am <span style={{ color: "#61dafb" }}>Miguel Olteanu</span>, a person
        whose goal is to improve and learn every day, both professionally and
        personally.
        <br />
        <br />I consider myself a{" "}
        <span style={{ color: "#61dafb" }}>dedicated person</span> to my
        objectives and <span style={{ color: "#61dafb" }}>perseverant</span>{" "}
        until I achieve them. This determination has led me to face challenges
        with confidence and not give up in the face of adversity, whether in
        personal projects or in my professional development.
        <br />
        <br />
        My approach is always to maintain a{" "}
        <span style={{ color: "#61dafb" }}>positive attitude</span> and value
        every experience that life offers me.
      </>
    ),
    professionalPath: "Professional Path",
    professionalPathContent: (
      <>
        Currently, I am looking for{" "}
        <span style={{ color: "#61dafb" }}>professional internships</span> to
        take my first steps in the workplace and apply my knowledge to real
        projects.
        <br />
        <br />
        During my studies, I have worked on{" "}
        <span style={{ color: "#61dafb" }}>projects</span> that have
        strengthened my skills in web and mobile development, as well as
        database management, theoretical foundations, and the creation of
        optimized interfaces.
      </>
    ),
    technicalSkills: "Technical Skills",
    technicalSkillsContent: (
      <>
        Throughout my experience, both at university and on my own, I have
        acquired skills in technologies such as{" "}
        <span style={{ color: "#61dafb" }}>JavaScript</span>,{" "}
        <span style={{ color: "#61dafb" }}>Java</span>,{" "}
        <span style={{ color: "#61dafb" }}>React</span>,{" "}
        <span style={{ color: "#61dafb" }}>Docker</span>,{" "}
        <span style={{ color: "#61dafb" }}>Git</span>,{" "}
        <span style={{ color: "#61dafb" }}>SQL</span> and{" "}
        <span style={{ color: "#61dafb" }}>Node.js</span>.
        <br />
        <br />
        Additionally, I have experience with databases, such as{" "}
        <span style={{ color: "#61dafb" }}>Firebase</span>, and in optimizing{" "}
        <span style={{ color: "#61dafb" }}>UX/UI</span>, which allows me to
        develop complete projects, from design to implementation.
      </>
    ),
    hobbies: "Hobbies and Interests",
    hobbiesContent: (
      <>
        Besides my career as a software engineer,{" "}
        <span style={{ color: "#61dafb" }}>sports</span> are fundamental in my
        life.
        <br />
        <br />I am a big fan of{" "}
        <span style={{ color: "#61dafb" }}>football</span>, a sport that I have
        enjoyed both as a spectator and as a player for as long as I can
        remember. I also like to{" "}
        <span style={{ color: "#61dafb" }}>exercise</span> daily, which not only
        helps me maintain a healthy life but also notably improves my mood.
        Additionally, I love watching{" "}
        <span style={{ color: "#61dafb" }}>TV series</span> and spending time
        playing and walking with my{" "}
        <span style={{ color: "#61dafb" }}>dogs</span>.
        <br />
        <br />
        These activities are essential for me, as they help me disconnect and
        bring balance to my life.
      </>
    ),
    nextSteps: "Next Steps",
    nextStepsContent: (
      <>
        My future focus is to continue developing my skills and specialize in
        web and mobile development, primarily using{" "}
        <span style={{ color: "#61dafb" }}>React</span>, due to its popularity
        and efficiency.
        <br />
        <br />
        Currently, I am working on the{" "}
        <span style={{ color: "#61dafb" }}>
          development of various projects
        </span>
        . This projects, in addition to creating something relevant, will allow
        me to increase my skills and experience.
        <br />
        <br />
        My main goal is to maintain continuous learning and constant
        improvement, both personally and professionally.
      </>
    ),
    viewCV: "View CV",
  },
};

function About() {
  const { isEnglish } = useLanguage();
  const lang = isEnglish ? "en" : "es";
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach((ref) => observer.observe(ref));

    return () => observer.disconnect();
  }, []);

  const sections = [
    {
      title: translations[lang].knowMe,
      content: translations[lang].knowMeContent,
      icon: <FaUser />,
    },
    {
      title: translations[lang].professionalPath,
      content: translations[lang].professionalPathContent,
      icon: <FaBriefcase />,
    },
    {
      title: translations[lang].technicalSkills,
      content: translations[lang].technicalSkillsContent,
      icon: <FaCode />,
    },
    {
      title: translations[lang].hobbies,
      content: translations[lang].hobbiesContent,
      icon: <FaFutbol />,
    },
    {
      title: translations[lang].nextSteps,
      content: translations[lang].nextStepsContent,
      icon: <FaRocket />,
    },
  ];

  return (
    <div className="about-container">
      {sections.map((section, index) => (
        <section
          key={index}
          className={`about-section ${index % 2 === 0 ? "left" : "right"}`}
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <div className="content-card">
            <h2 className="about-title">{section.title}</h2>
            <p className="about-description">{section.content}</p>
          </div>
          <div className="icon-container">{section.icon}</div>
        </section>
      ))}

      <a
        href={"https://drive.google.com/file/d/1YeEWkdJ2qx1BVmZO1cemsuqwfPDzXq7x/view?usp=drive_link"}
        target="_blank"
        className="cv-button-fixed"
        rel="noopener noreferrer"
      >
        <span className="cv-button-content">{translations[lang].viewCV}</span>
      </a>
    </div>
  );
}

export default About;
